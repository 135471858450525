import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { AssetImage } from '../../common';
import { baseBoxButton } from '../../button';
import { Link } from 'gatsby';

const CaseStudyCard = styled.div`
  background: #FFFFFF;
  box-shadow: 0 30px 60px 0 rgba(11,13,27,0.20);
  border-radius: 10px;
  margin: 0px 0px 100px 0px;
  text-align: left;

  @media only screen and (min-width: 700px) {
    display: flex;
  }
`;

const CaseStudyContent = styled.div`
  padding: 30px;
  flex: 1;
`;

const Info = styled.div`
  font-size: 14px;
  line-height: 29px;
`;

const Name = styled.span`
  color: #6E87EE;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 34px;
  color: #30323D;
  line-height: 1.5;

  @media only screen and (max-width: 700px) {
    margin: 10px 0;
  }

  @media only screen and (min-width: 700px) {
    margin: 30px 0 20px 0;
  }
`;

const Description = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: #4F5A79;
  line-height: 31px;
  margin: 10px 0;
`;

const ImageHolder = styled.a`
  flex: 0 0 auto;
  height: 100%;
  position: relative;
  display: flex;
`;

const ImageLogo = styled.div`
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 220px;
  height: 70px;
  padding: 5px 20px 2px 20px;
  background: #FFFFFF;
  box-shadow: -60px -18px 54px 0 rgba(0,0,0,0.50);
  border-radius: 43px 0 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ReadMore = styled(Link)`
  ${baseBoxButton}

  margin: 10px 0;
  display: inline-block;
  background: #637BE610;
`;

const CaseStudy = ({ pic, logo, title, description, link }) => {
  return (
    <CaseStudyCard>
      <CaseStudyContent>
        <Info>
          <Name>Case Study</Name>
        </Info>
        <Title>{title}</Title>
        <Description>{description}</Description>
        <ReadMore to={link}>Read More</ReadMore>
      </CaseStudyContent>
      <ImageHolder href={link}>
        <AssetImage src={pic} alt="name" />
        <ImageLogo>
          <AssetImage src={logo} alt="name" />
        </ImageLogo>
      </ImageHolder>
    </CaseStudyCard>
  );
};

CaseStudy.propTypes = {
  pic: PropTypes.string,
  logo: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
};

export default CaseStudy;
