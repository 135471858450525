import React from 'react';
import styled from 'styled-components';
import { SectionHeader, SectionDescription } from '../../common';
import Container from '../../container';
import CaseStudy from './caseStudy';

import listData from './../../../data/casestudies/list.yaml';

const CaseStudyContainer = styled(Container)`
  margin-bottom: 100px;

  @media only screen and (max-width: 700px) {
    text-align: center;
  }
`;

const CaseStudies = () => {
  const list = listData || [];
  return (
    <section id="caseStudies">
      <CaseStudyContainer>
        <SectionHeader>Case Studies</SectionHeader>
        <SectionDescription>Learn why our customers love us.</SectionDescription>
        {list.map((item, index) => {
          return (
            <CaseStudy key={String(index)} pic={item.image} title={item.snippet} logo={item.logo} link={`/${item.page}`} />
          )
        })}
      </CaseStudyContainer>
    </section>
  );
};

export default CaseStudies;
