import React from "react";
import styled from "styled-components";

import data from "../data/resources.yaml";
import ResourcesBackground from "../assets/resources/ResourcesBackground.svg";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Intro from "../components/pages/resources/intro";
import CaseStudies from "../components/pages/resources/caseStudies";
import WhitePapers from "../components/pages/resources/whitePapers";

export const PaleBoxOverlay = styled.div`
  position: absolute;
  z-index: -1;
  right: -100px;
  top: -450px;
  height: 1000px;
  width: 1000px;

  background:
      url(${ResourcesBackground})
      0px 0px / 100% 100%
      no-repeat
      border-box;
`;

const ResourcesPage = () => (
  <Layout
    navBackgrounds={[
      <PaleBoxOverlay key='PurpleBoxOverlay' />
    ]}
  >
    <SEO
      title="Resources"
      keywords={[`percept.ai`, `customer support`, `artifical intelligence`]}
    />

    <Intro data={data} />
    <CaseStudies />
    <WhitePapers />
  </Layout>
);

export default ResourcesPage;
