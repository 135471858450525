import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { CenteredMainTitle, CenteredMainDescription } from "../../intro/common";
import Container from '../../container';

const IntroContent = styled(Container)`
  margin: 50px auto 200px auto;
  position: relative;
`;

const Intro = ({ data }) => {
  const { title, subtitle } = data.intro;

  return (
    <section id="intro">
      <IntroContent>
        <CenteredMainTitle>{title}</CenteredMainTitle>
        <CenteredMainDescription>{subtitle}</CenteredMainDescription>
      </IntroContent>
    </section>
  );
};

Intro.propTypes = {
  data: PropTypes.object,
};

export default Intro;
