import React from 'react';
import styled from 'styled-components';
import { withPrefix } from 'gatsby';

import Container from '../../container';

import whitepaperVisual from '../../../assets/resources/WhitepaperBackground.png';
import { baseBoxButton } from '../../button';

const WhitePapersContainer = styled(Container)`
  margin: 200px auto 100px;

  @media only screen and (max-width: 700px) {
    text-align: center;
  }
`;

const WhitePaperSection = styled.div`
  margin: 50px 0;

  @media only screen and (min-width: 700px) {
    display: flex;
    flex-flow: ${props => props.reverse ? 'row-reverse' : 'row'}
  }
`;

const WhitePaperText = styled.div`
  flex: 1 0 30%;
  padding: 0 20px;
  margin: 0 20px 0 0;
`;

const WhitePaperCardContainer = styled.div`
  flex: 1 0 40%;
`;

const BigTitle = styled.div`
  font-size: 135px;
  color: #F4F4F4;
  line-height: 58px;
  margin: 80px 0 40px 0;
`;

const Title = styled.div`
  font-size: 45px;
  color: #30323D;
  line-height: 58px;
  margin: 10px 0;
`;

const Description = styled.div`
  font-size: 16px;
  color: #4F5A79;
  line-height: 30px;
  margin-bottom: 10px;
`;

const WhitePaperCard = styled.a`
  display: block;
  background: #FFFFFF;
  box-shadow: 0 40px 100px 0 rgba(11,13,27,0.08);
  border-radius: 8px;
  overflow: hidden;
  text-decoration: none;

  &:visited {
    color: black;
  }

  &:hover {
    box-shadow: 0 40px 100px 0 rgba(11,13,27,0.15);
  }
`;

const WhitePaperCardContent = styled.div`
  text-align: left;
  color: #30323D;
  line-height: 30px;

  @media only screen and (max-width: 700px) {
    padding: 20px;
  }

  @media only screen and (min-width: 700px) {
    padding: 30px 60px;
  }
`;

const ReadMore = styled.a`
  ${baseBoxButton}

  margin: 10px 0;
  display: inline-block;
  background: #637BE610;
`;

const WhitePaperCardTitle = styled.div`
  font-size: 24px;
  color: black;
  margin-bottom: 10px;
`;

const WhitePaperCardDescription = styled.div`
`;

const ImageHolder = styled.div`

`;

const Image = styled.img`
  width: 100%;
`;

const WhitePapers = () => {
  return (
    <section id="whitePapers">
      <WhitePapersContainer>
        <WhitePaperSection>
          <WhitePaperText>
            <BigTitle></BigTitle>
            <Title>Read our White Paper</Title>
            <Description>
              The purpose of this article is to give leaders interested in AI for customer support a general guideline on how to best evaluate different solutions, and help decide whether the technology is right for their business.
            </Description>
            <ReadMore href={withPrefix('/resources/enhancing-customer-support-with-AI-5-crucial-metrics-to-consider.pdf')}>
              Read More
            </ReadMore>
          </WhitePaperText>
          <WhitePaperCardContainer>
            <WhitePaperCard href={withPrefix('/resources/enhancing-customer-support-with-AI-5-crucial-metrics-to-consider.pdf')}>
              <ImageHolder>
                <Image src={whitepaperVisual} />
              </ImageHolder>
              <WhitePaperCardContent>
                <WhitePaperCardTitle>
                  Enhancing Customer Support with AI - 5 crucial metrics to consider
                </WhitePaperCardTitle>
                <WhitePaperCardDescription>
                  After reading this article, you should be able to understand what specific benefits your company might expect from the AI product you’re considering, and properly assess whether or not it’s worth moving forward to a deeper investigation of that product.
                </WhitePaperCardDescription>
              </WhitePaperCardContent>
            </WhitePaperCard>
          </WhitePaperCardContainer>
        </WhitePaperSection>
      </WhitePapersContainer>
    </section>
  );
};

export default WhitePapers;
